import React from 'react';
import { Button } from 'reactstrap';

const Element1 = (props) => {
return(
    <div className="wrap1">
        <h1>GRAYSCALE</h1>
        <p>A free, responsive, one page Bootstrap theme created by Start Bootstrap.All about this project can view clicking this button.</p>
        <Button color="info">info</Button>
        <div className="wrap1a">
            <h1>Reprehenderit amet veniam consectetur fugiat duis deserunt qui tempor.</h1>
            <p>Amet ipsum quis deserunt duis laborum et dolore adipisicing. Est proident ad minim non aliquip laboris id ut ad qui. Quis ipsum est enim incididunt elit nisi consectetur nulla laboris. Commodo culpa reprehenderit in adipisicing voluptate ea adipisicing cillum fugiat. Enim laborum anim eu culpa. Lorem pariatur esse enim ea.</p>
        </div>
    </div>
)


}

export default Element1;