import React from 'react';


const Element2 = (props) => {
    return(
        <div className="wrap2">
            <h1>Reprehenderit amet veniam consectetur fugiat duis deserunt qui tempor.</h1>
            <p>Amet ipsum quis deserunt duis laborum et dolore adipisicing. Est proident ad minim non aliquip laboris id ut ad qui. Quis ipsum est enim incididunt elit nisi consectetur nulla laboris. Commodo culpa reprehenderit in adipisicing voluptate ea adipisicing cillum fugiat. Enim laborum anim eu culpa. Lorem pariatur esse enim ea.</p>
        </div>
    )
}

export default Element2;