import React from 'react';
import {  Row, Col } from 'reactstrap';

const Element3 = (props) => {
    return(
        <div className="wrap3 mb-md-5">
        <Row>
          <Col lg="6" className="elem3Img">.col-6</Col>
          <Col lg="6" className="elem3Text">
          <h1>Non consequat veniam dolor aute elit deserunt non occaecat officia sit laboris consequat occaecat voluptate.</h1>
          <p>Consequat fugiat laboris aliqua ad ex consequat adipisicing ea aliqua ea. Proident ea aliquip mollit officia incididunt incididunt do aliquip aliqua reprehenderit. Tempor velit ex Lorem voluptate irure esse elit ex anim occaecat. Cupidatat quis ut sint id magna excepteur mollit irure. Esse duis mollit dolore ea aliquip aliquip mollit proident qui elit adipisicing reprehenderit in. Sunt adipisicing non qui est. Sit commodo voluptate commodo commodo magna pariatur officia quis.</p>
          </Col>
        </Row>
        </div>
    )
}

export default Element3;