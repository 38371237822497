import React from 'react';
import { Row, Col , Card,  CardBody,
    CardTitle, CardSubtitle, Button, Form,  Input ,InputGroup, InputGroupAddon } from 'reactstrap';


const Element5 = (props) => {
    return(
        <div className="wrap5 mt-md-5">
            <Row>
                <Col xs="12" className="form">

                <h1 className="text-center mt-5 subscribe">Subscribe to receive updates!</h1>
                <Form className="mb-5 mt-3">
                <Row form>
                    <Col md="8" className="m-md-auto">
                        
                    <InputGroup>
                        <Input />
                        <InputGroupAddon addonType="append">
                        <Button  color="secondary">Subscribe</Button>
                        </InputGroupAddon>
                    </InputGroup>
                    </Col>

                
                    </Row>
                </Form>

                </Col>

                <Col lg="4" className="elem5Bkg">
                    <Card>
                        <div className="cardImg1"></div>
                        <CardBody className="text-center">
                        <CardTitle>Address</CardTitle>
                        <CardSubtitle>4923 Market Street, Orland FL</CardSubtitle>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg="4" className="elem5Bkg">
                    <Card>
                    <div className="cardImg2"></div>
                        <CardBody className="text-center">
                        <CardTitle>Email</CardTitle>
                        <CardSubtitle>hello@yourdomain.com</CardSubtitle>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg="4" className="elem5Bkg">
                    <Card>
                        <div className="cardImg3"></div>
                        <CardBody className="text-center">
                        <CardTitle>Phone</CardTitle>
                        <CardSubtitle>+1(555) 902-8832</CardSubtitle>
                        </CardBody>
                    </Card>
                </Col>
                    <Col md="12" className="Iconlinks">
                    <div className="imgLinkedIn"></div>
                    <div className="imgTwitter"></div>
                    <div className="imgFacebook"></div>
                </Col>
                <p className="footerP mb-5">Copyright @ Your Website 2018</p>
            </Row>
        </div>
    )
}

export default Element5;