import React from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    } from 'reactstrap';


export default class Navigation extends React.Component{
    constructor(props){
        super(props);
        this.scrollColor = this.scrollColor.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen:false
        };
    }
    toggle(){
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    scrollColor(){
        if(window.pageYOffset > 50){
            // document.querySelector('.navbar').setAttribute('style', 'background:white');
            document.querySelector('.navbar').classList.add('navColor');
        }else{
            // document.querySelector('.navbar').setAttribute('style', 'background:transparent');
            document.querySelector('.navbar').classList.remove('navColor');
        }

    }
    componentDidMount() {
    window.addEventListener('scroll', this.scrollColor);
    }
    render(){
        return(
            <div className="wrapNav">
                <Navbar  light fixed="True" expand="md">
              
                
                    <NavbarBrand href="#home">reactstrap</NavbarBrand>
                    <NavbarToggler onClick={this.toggle}/>
                        <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink href="#info">Conmponent</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#more">git</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#footer">Contact</NavLink>
                        </NavItem>
                        </Nav>
                        </Collapse>

              
                </Navbar>
            </div>
        );
    }
}