import React from 'react';
import { Row, Col } from 'reactstrap';

const Element4 = (props) => {
    return(
        <div className="wrap4 mb-md-5 mt-md-5" >
        <Row>
          <Col md={{ size: 6 }} className="elem4Img1 elem4"></Col>
          <Col md={{ size: 6 }} className="elem4 elem4Bkg">
          <h1>Misty</h1>
          <p>Incididunt culpa ut do consectetur ullamco sunt minim aliqua laboris.And other nice idea.</p>
          </Col>
          <Col  md={{ size: 6, order:3 }} xs={{ order:4 }} className="elem4 elem4Bkg invertText">
          <h1>Mountains</h1>
          <p>Nulla eiusmod mollit esse velit voluptate esse incididunt in laborum.And other nice idea.</p>
          
          </Col>
          <Col md={{ size: 6, order:4 }} xs={{ order:3 }} className="elem4Img2 elem4"></Col>
        </Row>
        </div>
    )
}

export default Element4;