import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';


import './App.scss';
import Element1 from './components/element1';
import Element2 from './components/element2';
import Element3 from './components/element3';
import Element4 from './components/element4';
import Element5 from './components/element5';
import Navigation from './components/navigation';

class App extends Component {
  render() { 
    return (
      <div className="App">

      <Navigation/>
      
      <Container fluid>
      
        <Row>
          <Col className="element1" id="home">
            <Element1/>
          </Col>
        </Row>
        <Row>
          <Col className="element2" id="info">
            <Element2/>
          </Col>
        </Row>
        <Row>
          <Col className="element3 mb-5" id="more">
            <Element3/>
          </Col>
        </Row>
        <Row>
          <Col className="element4">
            <Element4/>
          </Col>
        </Row>
        <Row>
          <Col className="element5 mt-md-5" id="footer">
            <Element5/>
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}

export default App;
